<template>
  <el-dialog
    :title="`${isEdit === true ? '编辑' : '新增'}角色`"
    :visible.sync="show"
    width="600px"
    center
  >
    <el-form
      v-if="show"
      :model="form"
      :label-width="formLabelWidth"
      label-position="left"
      ref="form"
      :rules="rules"
      v-loading="loading"
    >
      <el-form-item label="角色编码" prop="code">
        <el-input v-model="form.code" placeholder="请输入角色编码" :disabled="isEdit===true"></el-input>
        <!-- <el-select v-model="form.code" placeholder="请选择">
          <el-option
            :label="item.name"
            :value="item.code"
            v-for="item in tableData"
            :key="item.id"
          ></el-option>
        </el-select> -->
      </el-form-item>
      <el-form-item label="名称" prop="name">
        <el-input v-model.number="form.name" autocomplete="off"></el-input>
      </el-form-item>

      <el-form-item label="权限" prop="auths">
        <!-- <el-checkbox v-model="isSelectAll" @change="selectAll"
          >选中全部</el-checkbox
        >
        <el-checkbox v-model="isExpand" @change="expandAll"
          >展开全部</el-checkbox
        > -->
        <el-tree
          :data="treeData"
          show-checkbox
          default-expand-all
          node-key="route"
          ref="tree"
          highlight-current
          :props="defaultProps"
          @node-click="expandAll"
        >
        </el-tree>
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <el-radio v-model="form.status" label="normal">正常</el-radio>
        <el-radio v-model="form.status" label="forbidden">隐蔽</el-radio>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="show = false">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import API from "@/api";
export default {
  name: "addForm",
  props:{
    tableData:{
      type:Array
    }
  },
  data() {
    return {
      formLabelWidth: "100px",
      form: {
        auths: [],
        name: "",
        status: "normal",
        code: "",
      },
      treeData: [],
      // tree是否全选，复选框默认状态（）
      isSelectAll: false,
      // tree是否展开
      isExpand: false,
      isEdit: false,
      show: false,
      defaultProps: {
        children: "child",
        label: "title",
      },
      rules: {
        code: [{ required: true, message: "请输入父级", trigger: "blur" }],
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
      },
      loading:false,
      parentsList:[]
    };
  },
  created() {
    this.getList();
  },
  methods: {
    submit() {
      const funName = this.isEdit ? "updateData" : "addData";
      this.$refs.form.validate((valid) => {
        valid ? this[funName]() : "";
      });
    },
    updateData() {
      let treeLists = this.$refs.tree.getCheckedNodes()
      let treeHalfNode = this.$refs.tree.getHalfCheckedNodes()
      let list = treeLists.concat(treeHalfNode)
      if(list.length){
        this.form.auths = list.map((item)=>{
          return {method:item.method,path:item.route}
        })
      }
      API.updataRolesList(this.form)
        .then((res) => {
          if(!res.code){
            this.$message.success("编辑成功");
            this.show = false;
            this.$parent.getList();
          }else{
            this.$message.error(res.msg);
            this.show = false;
          }
          
        })
        .catch(() => {
          this.$message.error("编辑失败");
        });
    },
    clearData() {
      this.form = {
        auths: [],
        name: "",
        status: "normal",
        code: "",
      };
      this.treeData = [];
    },
    editData(data) {
      this.form = data;
      this.isEdit = true;
      this.show = true;
      // 获取该条数据的权限
      this.getPowers(this.form.code)
    },
    addFormData() {
      this.$refs
      this.clearData();
      this.isEdit = false;
      this.show = true;
      //   有点小问题，不知道怎么优化，再次点击制空treeData 数据
      //   if (this.show === true) return this.treeData.length==0
      const newAuths = this.treeData.map((item) => [item.method, item.path]);
      const arrObj = newAuths.map((item) => {
        return { method: item[0], path: item[1] };
      });
      console.log(arrObj, "-----");
      //   this.form = {
      //     auths: arrObj,
      //     name: "",
      //     status: "normal",
      //     code: "",
      // //   treeData: [],
      //   };
      this.getList();
    },
    addData() {
      let treeLists = this.$refs.tree.getCheckedNodes()
      let treeHalfNode = this.$refs.tree.getHalfCheckedNodes()
      let list = treeLists.concat(treeHalfNode)
      if(list.length){
        this.form.auths = list.map((item)=>{
          return {method:item.method,path:item.route}
        })
      }
      API.createRolesList(this.form)
        .then((res) => {
          if(!res.code){
            this.$message.success("创建成功");
            this.show = false;
            this.$parent.getList();
          }else{
            this.$message.error(res.msg);
            this.show = false;
          }
          
        })
        .catch(() => {
          this.$message.error("创建失败");
        });
    },
    getList() {
      this.loading = true
      let params = {
        page: 1,
        size: 1000,
      };
      API.getMenuList(params)
        .then((res) => {
          if (res.code === 0 && res.data.data) {
            this.treeData = res.data.data;
            // // 处理父级下拉数据
            // this.parentsList = this.setParentsData(res.data.data,[])
          } else if (res.code === 401) {
            this.$message.error("登陆信息过期,请重新登陆!");
            return;
          } else {
            this.treeData = [];
          }
          this.loading = false;
        })
        .catch(() => {
          this.treeData = [];
          this.loading = false;
        });
    },
    // 全选/不全选
    // selectAll() {
    //   if (this.isSelectAll) {
    //     // 	设置目前勾选的节点，使用此方法必须设置 node-key 属性
    //     this.$refs.tree.getCheckedKeys(this.auths.data);
    //   } else {
    //     // 全部不选中
    //     // this.$refs.tree.setCheckedKeys([...keys, ...this.getAllNodeIds()]);
    //   }
    // },
    // // 展开/折叠
    expandAll(data) {
      data.expanded = !data.expanded; // 反转节点的展开状态
    },
    // // 处理树的数据给父级下拉框
    // setParentsData(data,arr){
    //   console.log(data,arr)
    //   data.forEach(item=>{
    //     arr.push({key:item.id,value:item.title})
    //     if(item.child && item.child.length>0){
    //       this.setParentsData(item.child,arr)
    //     }
    //   })
    //   return arr
    // },
    // 获取当前数据权限
    getPowers(code){
      this.loading = true
      API.getRbacRoles({role_id:code})
        .then((res) => {
          if(!res.code){
            let treedatas = res.data.map(item=>{ return item[1] })
            // 找出所有父节点数据
            const parents = []
            this.treeData.map(item => {
              parents.push(item.route)
              item.child.map(v=>{
                parents.push(v.route)
              })
            })
            // 从获取的已选择数据中过滤掉父节点id 获取多有子节点id
            const childrens = []
            treedatas.map(item => {
              if(!parents.includes(item)){
                childrens.push(item) 
              }
            })
            this.$refs.tree.setCheckedKeys(childrens)
            this.loading = false
          }else{
            this.loading = false
          }
        })
    }
  },
};
</script>

<style scoped>
</style>
