<template>
  <div
    class="videoList-box"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div class="top">
      <p class="topTitle">角色组</p>
      <div class="topText">
        角色组可以有多个,角色有上下级层级关系,如果子角色有角色组和管理员的权限则可以派生属于自己组别的下级角色组或管理员
      </div>
    </div>
    <refreshbtn @refesh="refesh">
      <template slot="search-btns">
        <el-button type="success" size="small" v-has="'/api/auth/admin/role/create'" @click="addFromMethod">添加</el-button>
      </template>
    </refreshbtn>
    <el-table
      :data="tableData"
      style="width: 100%"
      border
      ref="multipleTable"
      v-loading="loading"
      element-loading-text="加载中"
      element-loading-spinner="el-icon-loading"
      height="580"
      :key="updataSta"
    >
      <el-table-column prop="id" label="ID" align="center" width="180">
      </el-table-column>
      <el-table-column prop="code" label="角色编码" align="center" width="280">
      </el-table-column>
      <el-table-column prop="name" label="名称" align="center"> </el-table-column>
      <el-table-column label="状态" prop="status" align="center" width="200">
        <template slot-scope="scope">
          <i class="el-icon-circle-check" style="color: green"></i
          >{{ scope.row.status == "normal" ? "正常" : "隐蔽" }}
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" align="center" width="200">
        <template slot-scope="scope">
          <el-button type="text" size="small" v-has="'/api/auth/admin/role/update'" @click="editForm(scope.row)">
            编辑
          </el-button>
          <el-button type="text" size="small" v-has="'/api/auth/admin/role/destroy'" @click="deleteForm(scope.row.id)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页组件 -->
    <div class="bottom">
      <div class="page">
        <el-pagination
          :page-sizes="[10, 25, 50, 100]"
          :current-page="params.page"
          :page-size="params.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          background
          v-if="total > 10"
          @size-change="changeSize"
          @current-change="changeCurrent"
        ></el-pagination>
      </div>
    </div>
    <addRoles ref="addForm" :tableData="tableData"></addRoles>
  </div>
</template>

<script>
import API from "@/api";
import addRoles from "@/views/power/components/addRoles";
export default {
  name: "administrators",
  components: { addRoles },
  data() {
    return {
      params: {
        page: 1,
        size: 10,
      },
      loading: false,
      tableData: [],
      total: "",
      updataSta: false,
      dialogVisible: false,
      isRefesh:true,
    };
  },
  created() {
    let listRule = '/api/auth/admin/role/list'
    let obj = JSON.parse(JSON.parse(JSON.stringify(localStorage.getItem('btnPower'))));
    if(obj[listRule]){
      this.isRefesh = true
      this.getList()
    }else{
      this.isRefesh = false 
    }
  },
  methods: {
    refesh(){
      this.isRefesh?this.getList():''
    },
    // 添加
    addFromMethod() {
      this.$refs.addForm.addFormData();
    },
    // 编辑
    editForm(data) {
      this.$refs.addForm.editData(this._.cloneDeep(data));
    },
    // 删除
    deleteForm(id) {
      this.$confirm("确定删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          API.destoryRolesList({ id }).then((res) => {
            if(!res.code){
              this.$message.success("删除成功");
              this.getList()
            }else{
              this.$message.error(res.msg);
            }
          });
          
        })
    },
    // 获取数据
    getList() {
      this.loading = true
      API.getRolesList(this.params)
        .then((res) => {
          if (res.code === 0 && res.data.data) {
            this.tableData = res.data.data;
            this.total = res.data.pagination.count;
            this.loading = false
          } else if (res.code === 401) {
            this.$message.error("登陆信息过期,请重新登陆!");
            this.loading = false
            return;
          } else {
            this.tableData = [];
            this.total = 0;
            this.loading = false
          }
        })
        .catch(() => {
          this.tableData = [];
          this.loading = false;
        });
    },

    // 分页条数改变回调
    changeSize(size) {
      this.params.size = size;
      this.getList();
    },
    // 分页页码改变回调
    changeCurrent(number) {
      this.params.page = number;
      this.getList();
    },
    // 关闭窗口
    close() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.videoList-box {
  min-height: 100vh;
  overflow-y: auto;
  .top {
    padding: 10px;
    width: 100%;
    height: 80px;
    background: #e9edf0;
    margin-bottom: 20px;
    .topText {
      margin-top: 10px;
      font-size: 14px;
      color: #909399;
    }
  }
  .bottom {
    margin-top: 20px;

    .btn {
      display: inline-block;
      margin-left: 20px;
    }

    .page {
      display: inline-block;
      float: right;
    }
  }
}
</style>
